@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap');

html, body, *{
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #FEFEFE;
}

.container {
  max-width: 1000px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 15px;
  grid-auto-flow: row;
  /* grid-template-areas: ". ."; */
  /* min-height: 100vh; */
  justify-content: center;
  align-items: center;
}
.container > div{
  padding: 0 15px;
}

.mobile-banner{
  display: none;
}
.desktop-banner{
  display: block;
}
.mobile-banner, .desktop-banner{
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
}
.mobile-banner img, .desktop-banner img{
  width: 100%;
}

.form-roleta .form-group{
  margin-bottom: 22px;
}
.form-roleta .form-group input:not([type=checkbox]){
  height: 48px;
  width: 100%;
  padding: 0 10px;
  font-size: 16px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  border: 1px #090D39 solid;
}
.form-roleta button{
  height: 48px;
  padding: 0 15px;
  font-size: 16px;
  background: #090D39;
  color: #FFF;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  margin-bottom: 25px;
}
.form-roleta button:hover{
  background: #1f2672;
}
.form-roleta button[disabled]{
  background: #313670;
  cursor: not-allowed;
}

h2{
  margin-top: 0;
  margin-bottom: 0;
}
p{
  margin-top: 0;
  margin-bottom: 20px;
}

.wheel{
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  display: inline-block;
  width: 350px;
  height: 350px;
  position: relative;
  -webkit-box-shadow: 2px 1px 6px 0 rgba(0,0,0,0.3);
  box-shadow: 2px 1px 6px 0 rgba(0,0,0,0.3);
  overflow: hidden;
  display: inline-block;
  margin: 0;
}
.wheel .center{
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
  width: 80px;
  height: 80px;
  top: 135px;
  left: 135px;
  -webkit-box-shadow: 2px 1px 6px 0 rgba(0,0,0,0.3);
  box-shadow: 2px 1px 6px 0 rgba(0,0,0,0.3);
  background: #FCB61A;
  position: relative;
  z-index: 200;
  margin: 0;
  display: inline-block;
}
.wheel .faixa{
  position: absolute;
  left: 175px;
  top: 175px;
  width: 175px;
  height: 175px;
  background: #0F8ACA;
  color: #f2f2f2;
  text-align: center;
  z-index: 10;
}
.wheel .faixa:nth-child(3){
  background: #E4002B;
  transform: rotate(45deg);
  top: 212px;
  left: 88px;
  z-index: 20;
}
.wheel .faixa:nth-child(4){
  background: #0F8ACA;
  transform: rotate(90deg);
  top: 175px;
  left: 0px;
  z-index: 30;
}
.wheel .faixa:nth-child(5){
  background: #E4002B;
  transform: rotate(135deg);
  top: 88px;
  left: -36px;
  z-index: 40;
}
.wheel .faixa:nth-child(6){
  background: #0F8ACA;
  transform: rotate(180deg);
  top: 0px;
  left: 0px;
  z-index: 50;
}
.wheel .faixa:nth-child(7){
  background: #E4002B;
  transform: rotate(225deg);
  top: -36px;
  left: 88px;
  z-index: 60;
}
.wheel .faixa:nth-child(8){
  background: #0F8ACA;
  transform: rotate(270deg);
  top: 0px;
  left: 175px;
  z-index: 70;
}
.wheel .faixa:nth-child(9){
  background: none;
  transform: rotate(270deg);
  top: 0px;
  left: 175px;
  z-index: 70;
  overflow: hidden;
}
.wheel .faixa:nth-child(9) > div{
  position: absolute;
  background: #E4002B;
  width: 175px;
  height: 175px;
  transform: rotate(45deg);
  top: 36px;
  left: -87px;
}
.wheel .faixa span{
  position: absolute;
  top: 24px;
  left: 48px;
  transform: rotate(22.5deg);
}
#wheel-wrapper{
  position: relative;
  overflow: hidden;
}
#wheel-wrapper #seta{
  position: absolute;
  left: 340px;
  top: 160px;
  z-index: 1000;
  width: 0px;
  height: 0px;

  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent; 
  border-right: 15px solid #FCB61A; 
}

@media only screen and (max-width: 750px){
  .container{
    grid-template-columns: 1fr;
    min-height: auto;
  }
  .container > div:first-child{
    order: 2;
  }
  .container > div:last-child{
    order: 1;
  }
  .mobile-banner{
    display: block;
  }
  .desktop-banner{
    display: none;
  }
}

/* .wheel{
  animation-name: spin;
  animation-duration: 1500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
} */

/* @keyframes spin {
  from {
      transform:rotate(0deg);
  }
  to {
      transform:rotate(360deg);
  }
} */
